body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.MuiInputBase-input {
  color: #fff;
}
/*div.MuiMenu-paper {
  background: none;
}*/
input::placeholder,
.MuiInputLabel-root  {
  color: #fff;
}
.MuiStepper-horizontal {
  width: 100%;
  max-width: 30rem;
}
.MuiDataGrid-columnHeaderRow{
  color: #fff;
  font-size: 2rem;
}
.MuiTablePagination-toolbar{
  color: #fff;
}
.MuiDataGrid-columnHeaderRow{
  color: #fff;
  font-size: 2rem;
}
.MuiTablePagination-toolbar{
  color: #fff;
}
.MuiStepConnector-horizontal {
  top: 35px!important;
  width: 3rem;
  left: calc(-50% + 55px)!important;
}
.mui-calendar .Mui-disabled {
  background: rgba(217, 217, 217, 0.2);
}
.mui-calendar div .Mui-disabled {
  -webkit-text-fill-color: rgb(255, 255, 255)!important;
}
span.Mui-checked > span svg:first-child {
  color: #f95370;
}

.form-width-95 {
  width: 86%;
}
.form-width-75 {
  width: 75%;
}
form div .FormRadio {
  width: 100%;
  max-width: 24rem;
}
.MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
  align-items: center;
}

.react-select__menu-list {
  font-family: 'Lato';
  font-size: 0.9rem;
}
@media (max-width: 560px) {
  .MuiStepConnector-horizontal {
    top: 25px!important;
    width: 2em;
    left: calc(-50% + 30px)!important;
  }
}

.fix-margin {
  margin-right: 0!important;
}